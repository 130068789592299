<template>
  <div class="msp-attivita-detail mx-auto msp-fixed-container" >
  <div v-if="!attivita" class="page-skeleton page-loading">
  Loading...
  </div>
    <template v-if="attivita">
      <v-app-bar dark class="msp-fixed-header"   :color=" 'var(--col-sport-'+attivita.sport_id+')'" >
      <v-btn icon @click="dismissModal()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-toolbar-title>
        <i  class="ev-all__icon " :class="'msp-icon-sport-'+attivita.sport_id" alt=""></i>
                {{attivita.riepilogo.data_inizio}}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      </v-app-bar>
        <v-container fluid class="msp-fixed-content">
          <v-sheet v-if="attivita" v-cloak>
<div class="header-area">
      <div class="header-area__allenamento-titolo">
      <h3>{{attivita.riepilogo.titolo}}</h3>
      </div>
      <div class="header-area__allenamento-descrizione">
            <div class="dettaglio-allenamento-descrizione">
            <!-- scrivere titolo e feedback su tabella t_garmin_extra -->
              <div class="descrizione" v-if="attivita">
              <div v-if="attivita.riepilogo.sport">
                {{attivita.riepilogo.sport}}<br />
              </div>
              <span v-if="attivita.riepilogo.localita" v-text="attivita.riepilogo.localita"></span><br>
              <span class="meteo-condition" v-if="attivita.riepilogo.meteo_condition" v-text="attivita.riepilogo.meteo_condition"></span>
              <span>&nbsp;&nbsp;</span>
              <span class="meteo-temp" v-if="attivita.riepilogo.meteo_temp" >{{attivita.riepilogo.meteo_temp}} &deg;C</span>
              </div>

            </div>
          </div>
          </div>


    <component v-bind:is="component"
    :attivita="attivita"
    ></component>


      <v-btn v-show='!deleting' @click="deleteAttivita" block color='error'>Elimina attività</v-btn>
          <msp-confirm ref="confirmDelete"></msp-confirm>
      <v-alert
      v-show='deleting'
        dense
          text
            type="info"
            >Eliminazione in corso...</v-alert>
      <!-- attività -->
          </v-sheet>
      </v-container>

      
    </template>
  </div>
</template>



<script>
import mspBarChart from "@components/MspBarChart.vue";
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import {_canViewAttivita} from "@mixins/canEdit.js";
import api from "@mixins/api.js";
import mspVideos from '@components/mspVideos.vue'
import testDettaglio from '@components/testDettaglio.vue'
import env from "@mixins/environment.js"
import listaAttivita from '@components/listaAttivita.vue'
import mspGraficoAndature from '@components/MspGraficoAndature.vue'
import mspWorkout from '@components/MspWorkout.vue'
import mspConfirm from '@components/MspConfirm.vue'
import mspAttivitaPodismo from '@components/MspAttivitaPodismo.vue'
import mspAttivitaCiclismo from '@components/MspAttivitaCiclismo.vue'
import mspAttivitaSci from '@components/MspAttivitaSci.vue'
import mspAttivitaNuoto from '@components/MspAttivitaNuoto.vue'
import mspAttivitaPalestra from '@components/MspAttivitaPalestra.vue'
import {sport_id as _sport_ids} from '@src/js/msp/constants.js'
const _components = {
    [_sport_ids.podismo]: "mspAttivitaPodismo",
    [_sport_ids.ciclismo]: "mspAttivitaCiclismo",
    [_sport_ids.nuoto]: "mspAttivitaNuoto",
    [_sport_ids.sci]: "mspAttivitaSci",
    [_sport_ids.palestra]: "mspAttivitaPalestra",
};
const COUNT = 10;
const labels = [];
const sampledata = [];
for (let i=0;i<COUNT;i++) {
    labels.push('label-'+i);
    sampledata.push(i);
}
/*
*/








const component = {
  components: {
      mspBarChart,
    testDettaglio,
    mspVideos,
    listaAttivita,
    mspGraficoAndature,
    mspWorkout,
    mspConfirm,
    mspAttivitaPodismo,
    mspAttivitaCiclismo,
    mspAttivitaSci,
    mspAttivitaNuoto,
    mspAttivitaPalestra,
  },
  mixins: [
    allenamentoCalcoli,
    env,
  ],
  name: 'attivitaDettaglio',
  props: [
  ],
  watch: {
        attivita: function (no) {
            if (!(no)) {
                this.dismissModal();
            }
        }
  },
  beforeRouteEnter: async function (to, from, next) {
    let id = to.params.id;
          let canView = await _canViewAttivita(id);
    if (!(canView && from.name)) {
      next('calendario');
    } else {
        next();
    }
  },
    computed: {
        component: function () {
            if (!(this.attivita && this.attivita.sport_id && _components[this.attivita.sport_id])) {
                return null;
            }
            return _components[this.attivita.sport_id];
        },

    },
  data() {
    return {
      deleting: false,
      attivita: null,
      id: null,
    }
  },
  mounted() {
      this.id = this.$route.params.id;
      this.loadAttivita()
      .then(
        (result) => {
                this.attivita = result;
            }
        )
  },

  methods: {

    formatData: function (ts) {
      let d = new Date(ts * 1000);
      return d.toLocaleDateString('it');
    },
    formatDataForMySQL: function (ts) {
      let d = this.formatDataOra(ts).split(",").map(el=> el.split("/").reverse().join("-").trim()).join(" ");
      return d;
    },
    formatDataOra: function (ts) {
      let d = new Date(ts * 1000);
      return d.toLocaleString('it');
    },
    formatSport: function (s) {
      return s.replace("_", " ");
    },
    formatIconSport: function (s) {

      return 'msp-icon-sport-' + s.toLowerCase();
    },
    formatTime: function (string) {
      if (!string)
      {
        return "";
      }
      if (!this.isValidDate(string))
      {
        return string;
      }
      return new Date(string).toLocaleString("it");

    },

    dismissModal () {
      this.$router.go(-1);
    },



    async deleteAttivita() {
      if (
        await this.$refs.confirmDelete.open(
          "Elimina",
          "L'attività verrà cancellata definitivamente.<br> Sei sicuro?"
        )
      ) {
        this.deleteAttivitaConfirm();
      }
    },
    deleteAttivitaConfirm: async function () {
        if (!this.id) return Promise.resolve(false);
        const id = this.id;
        return new Promise((resolve)=>{
            let url = this.api_base_url + '/api/v2/attivita.php?id='+id;
            this.deleting = true;
            api(url, 'DELETE')
                .then((result)=>{
                    if(result.success) {
                        this.$store.dispatch("calendario/removeAttivita",{id:id});
                        this.deleting = false;
                        resolve(true);
                        this.dismissModal();
                    } else {
                        this.deleting = false;
                        resolve(false);
                    }
                })
                .catch(()=>{
                    this.deleting = false;
                    resolve(false);
                })
        });
    },

      loadAttivita: function () {
          if (!this.id) return Promise.resolve(false);
          const id = this.id;
          return new Promise((resolve)=>{
              let url = this.api_base_url + '/api/v2/attivita.php?mobile&id='+id;
              api(url, 'GET')
                  .then((result)=>{
                      if(result.success) {
                          resolve(result.data);
                      } else {
                          resolve(false);
                      }
                  })
                  .catch(()=>{
                      resolve(false);
                  })
          });



      },


  }
}
export default component;
</script>
<style lang="scss">
.page-skeleton {
    background: #fff;
    height: 100vh;
    color: var(--col-grigio-chiaro);
    font-size: 3em;
    display: grid;
    align-items: center;
    justify-content: center;
}
.msp-attivita-detail {
    .tabella-lap {
        .v-data-table__wrapper {
            max-height: 400px;
        }
    }
    table.dati {
        width: 100%;
        table-layout: fixed;

        th {
            font-weight:bold;
            text-align: left;
            border-bottom: 1px solid #ccc;  
        } 
        td {
            text-align: left;
            border-bottom: 1px solid #ccc;  
        } 
        .label {
            font-weight:bold;

        }
    }
    .msp-card {
        --bg-col: var(--col-grigio-chiarissimo);
        border-radius: 5px;
        dispay: flex;
        flex-direction: column;
        background-color: var(--bg-col);
        padding: 5px;
        margin: 10px 5px; 
    }
    .msp-card__title {
        text-align: center;
        padding: 0px;
    }

    .msp-card__content {
        font-weight: bold;
        font-size: 1em;
        text-align: center;
        padding: 0px;
        text-align: center;
    }

    .boxes {
        display: flex;
        .msp-card {
            flex: 1;
        }
    }

    .v-tab {
        padding: 0;
        min-width: 45px;
        .small {
            letter-spacing: 0;
            text-transform: none;
            font-size: 9px;
        }
    }
    .grafico-andature {
        margin: 5px 0;
        padding: 5px 5px 0 5px;
        display: flex;
        height: 50px  ;
        .msp-grafico-andature {
            max-width: 500px;

        }

    }

    .attivita-dettaglio {
        .msp-andature-dettaglio {
            display: none;
        }
        .note-attivita {
            display: block;
        }
        &.collapsed {
            .attivita-palestra-col2,
            .attivita-palestra-col3 {
                display: none !important;
            }
        }
        &.auto {
            .msp-andature-dettaglio {
                display: block;
            }
            .note-attivita {
                display: none !important;
            }

        }
        gap: 2px;
        flex-wrap: wrap;
        .dati-item {
            display: flex;
            flex: 1;
            white-space: nowrap;
            padding: 0 10px;
            align-items: center;
            background: var(--col-grigio-chiarissimo);
            gap: 3px;
            border-radius: 5px;
        }
        .dati-value {
            font-weight: bold;

        }
    }
    .attivita-feedback-container {
        overflow-x: auto;
        width: 100%;
        position: relative;
    }
    .note-allenatore {
        padding: 5px;
        border: 2px solid var(--col-ui-evidenza);
        border-radius: 5px;
        margin: 5px 0;
        background: var(--col-ui-evidenza-tint);
    }

    .v-app-bar {
        .v-toolbar__title {
            display: flex;
            align-items: center;
            line-height: 2em;
            [class*="msp-icon-sport-"] {
                font-size: 2em;
                margin-left: 0;
                margin-right: 20px;

            }
        }
    }
    h2 {
        font-size: 1.5em;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    .small {
        font-size: 10px;
        display: block;
    }
    .v-avatar {
        i {
            font-size: 80px;
        }

    }

    .scheda-toolbar {
        background-color: var(--color) !important;
        color: #fff;
    }

    .test-attivi {
        .zone {
            overflow-x: auto;
        }
    }
    .switch-mode {
        margin: 5px 10px;
    }
    .chip-percentuale {
        font-size: 30px;
        font-weight: bold;
    }
    .msp-segment {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 2px;
        --background: var(--col-grigio-chiaro);
        --color: var(--col-grigio-scuro);
        &__item {
            line-height: 1;
            text-align: center;
            background-color: var(--background);
            padding: 5px 10px;
            color: var(--color);
            display: grid;
            place-content: center;
            input {
                display: none;
                appearance: none;
                margin: 0;
                padding: 0;
                width: 0;
            }
        }

    }
    .msp-segment__item:has(input:checked) {
        font-weight: bold;
        --background: var(--col-msp);
        --color: #fff;
    }


}

</style>
