const rangeZone = [
    {
        "id": 0,
        "name": "zona intera",
        "da": 0,
        "a": 100,
    },
    {
        "id": 1,
        "name": "bassa",
        "da": 0,
        "a": 33.33333333,
    },
    {
        "id": 2,
        "name": "centrale",
        "da": 33.33333333,
        "a":  66.66666666,
    },
    {
        "id": 3,
        "name": "alta",
        "da": 66.66666666,
        "a": 100,
    },
];
export default rangeZone;
