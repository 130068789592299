<template>
  <div class="input-select" :class="classOptions" >
  <div class="input-select__content">
      <input 
      class="input-select__input"
      :placeholder="placeholder"
      @focus="selectAll"
      @blur="onBlur"
      :value="display"
      @input="onInput"
      ref="input"
      >

      <div v-if="hasOptions" class="options-container">
      <select class="msp-select" v-model="selected" @change="selectOption" >
      <option
      :key="i"
      v-for="(option,i) in my_options" :value="option">{{option}}
      </option><!--  -->
      </select>
      </div>
      <span v-if="invalid" class="alert">Controlla i valori</span>
  </div>
  </div>
</template>

<script>
    import  _funzioni from '../../msp/funzioni.js'
    import  _storage from '../../msp/storage.js'

    const component = {
        props: ["value", "format", "options", "placeholder"],
        data: function() {
            return {
                cursorPos: 0,
                selected: null,
                isOptions: false,
                isInput: true,
                lastValue: null, // avoid unecessary emit when has no change
                display: this.value,
                invalid: false,
                digits: 6,
                altro: 'altro...'

            };
        },
        watch: {
            value: function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.display = this.getRefreshedValue(newValue);
                }
            },
            format: function () {
                if(this.isHms) {
                    this.initDigits();
                    this.display = this.getRefreshedValue(this.value);
                }
            },
        },
        mounted: function () {
            //do something after mounting vue instance
            this.initOptions();

            if(this.isHms) {
                this.initDigits();
            }
            this.display = this.getRefreshedValue(this.value);
        },
        computed: {
            widthInput: function () {
                const defaultValue = "10ch";
                if (!this.display) {
                    return defaultValue;
                }
                return this.display.length + 5 + 'ch'
            },
            //do something after mounting vue instance
            hasOptions: function () {
                return this.options && (this.options.length > 0);
            },
            my_options: function () {
                var opts = [];
                if (this.hasOptions) {

                    if (typeof this.options === 'string') {
                        opts = [].concat(this.options.split(","), this.altro)
                    }
                    opts = [].concat(this.options);

                    if (!this.isSelected() && this.display) {
                        opts = opts.concat(this.display);
                    }
                    opts = this.addAltro(opts);

                }
                return opts;
            },

            classOptions: function () { 
                let classes = [];
                if (this.hasOptions) {
                    classes.push("select");
                }
                this.adjustPosition();
                return classes.join(" ");
            },

            isHms: function () {
                let isHms = (this.format && (this.format.indexOf('ms') > -1));
                return isHms;
            }


        },
        methods: {

            initOptions: function () {
                //nothing to do...

            },
            initDigits: function () {
                if (this.format === "ms") {
                    this.digits = 4;
                } else {
                    this.digits = 6;
                }

            },

            addAltro: function (arr) {
                if (arr.indexOf(this.altro) === -1) {
                    arr.push(this.altro);
                }
                return arr;
            },
            setFocus: function () {
                this.$nextTick(()=>{
                this.$el.querySelector('input').focus();
                });
            },
            isSelected: function () {
                return this.options.indexOf(this.display) > -1;
            },
            selectOption: function (ev) {
                this.$nextTick(()=>{
                    let option = ev.target.value;
                    this.selected = (option === this.altro) ? "" : option;
                    this.display = this.selected;
                    this.refresh(this.display);
                    this.hideOptions();
                    this.setFocus();
                });
            },

            classOptionSelected: function (option) {
                return (option === this.selected) ? "selected" : "";
            },

            hideOptions: function () {
                this.isOptions = false;
            },

            selectActive: function () {
                var sel = this.my_options.indexOf(this.display);
                if (sel > -1) {
                    this.selected = this.display;
                }
            },

            adjustPosition: function () {
            },

            showOptions: function () {
                this.isOptions = true;
                this.$nextTick(()=>{
                    const el = this.$el.querySelector('select');
                    el.focus();
                    el.click();
                });
                this.selectActive();
            },

            toggleOptions: function () {
                if (this.isOptions) {
                    this.hideOptions();
                } else {
                    this.showOptions();
                }
            },

            setCursorPosition: function (pos) {
                this.cursorPos = pos;
            },


            setSelectionRange: function (start,end) {
                this.$nextTick(()=>{
                    const i = this.$refs.input;
                    i.setSelectionRange(start, end);
                },0);
            },

            updateCursorPosition: function () {
                if (this.isHms) return;
                this.setSelectionRange(this.cursorPos, this.cursorPos);
            },

            selectAll: function () {
                const self = this;
                const i = this.$refs.input;
                var sel = function () {
                    self.setSelectionRange(0, i.value.length)
                };
                setTimeout(sel, 200);
            },

            onInput (e) {
                this.setCursorPosition(e.target.selectionStart);
                let newValue = e.target.value
                this.selected = newValue;
                this.$nextTick(()=>{
                    this.refresh(newValue);
                });
            },

            onBlur: function (e) {
                let newValue = e.target.value
                this.selected = newValue;
                this.$nextTick(()=>{
                    this.$emit("blur",newValue);
                });
            },
            toOnlyNumber: function (val) {
                if (val===null) return;
                if (typeof val === 'number') {
                    return val;
                } else {
                    return val.replace(/[\D]+/g, '');
                }
            },
            toInteger: function (val) {
                return _funzioni.toInteger(val);
            },
            toNumber: function (val) {
                return _funzioni.toNumber(val);
            },
            pad: function (val, l) {
                var pad = "000000";
                var pad_val = (pad+val).substr(-l);
                return pad_val;

            },
            toHms: function (val) {
                let h,m,s;
                if (this.format === 'ms') {
                    m = parseInt(val.substr(0,2), 10);
                    s = parseInt(val.substr(2,2), 10);
                    this.invalid = false;

                    if (s > 59) {
                        this.invalid = true;
                    }
                    if (m > 59) {
                        this.invalid = true;
                    }
                    return (this.pad(m,2)+":"+this.pad(s,2));
                } else {
                    h = parseInt(val.substr(0,2), 10);
                    m = parseInt(val.substr(2,2), 10);
                    s = parseInt(val.substr(4,2), 10);
                    this.invalid = false;

                    if (s > 59) {
                        this.invalid = true;
                    }
                    if (m > 59) {
                        this.invalid = true;
                    }

                    return (this.pad(h,2)+":"+this.pad(m,2)+":"+this.pad(s,2));
                }

            },

            shift: function (val) {
                if (val===null) return;
                val = this.toOnlyNumber(val);
                val = this.pad(val, this.digits);
                val = this.toHms(val);
                return val;
            },

            debounceEmitInput: function (display)
            {
                clearTimeout(_storage.search);
                _storage.search = setTimeout(
                    ()=>{
                        this.$emit('input', display);
                    }, 300
                )
            },

            getRefreshedValue: function (value) {
                if (value === undefined) { return; }

                if (this.isHms) {
                    var self = this;
                    value = self.shift(value);
                } else if (this.format === 'number') {
                    value = this.toNumber(value);
                }
                if ((typeof value === "number") && isNaN(value)) {
                    value = 0;
                }
                return value;
            },

            refresh: function (value) {
                value = this.getRefreshedValue(value);
                this.display = value;
                this.updateCursorPosition();
                this.debounceEmitInput(this.display);
                return value;
            },

            openSelect: function () {
                this.showOptions();
            },

        }
    }
    export default component;
</script>

<style lang="scss">
.input-select {
    position: relative;
    padding: 0 !important;
    --ui_input_select_button_width: 27px; //era 30
    &__content {
        display: flex;
        height: 100%;
        margin-bottom: 0.1em;
    }
    &__input {
        height: 100%;
        min-width: 3em;
    }
        select {
            /* styling */
            background-color: transparent;
            border: thin solid var(--col-grigio-chiaro);
            border-radius: 4px;
            display: inline-block;
            font: inherit;
            padding: 5px 20px 0px 0;
            width: 20px !important;
            overflow: hidden;


            /* reset */

            margin: 0;
            box-sizing: border-box;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 100px;
            text-overflow: '';
            font-size: 0em;
        }


        select:focus {
            padding: 0.5em;
            font-size: 1em;

        }

    .input-select__shadow {
        height: 0px;
        overflow: hidden;
        padding-right: 40px;
        position: relative;
        width: 100%;

    }

    &.editing {

        .input-select__shadow {
            opacity: 0;
            margin-top: -100%;
        }
    }
    input {
        width: 100%;
        border: 0;
        padding-left: 5px;
    }
    &.select input {
        padding-right: 5px;
        margin-right: 0;
    }

    input {
        display: block;
        flex-grow: 1;
    }


    .options-trigger {
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }
    .options-container {
        // width: var(--ui_input_select_button_width);
        position: relative;
        display: flex;
        justify-content: flex-end;
        background-color: var(--col-msp-chiaro);
        height: 100%;
        &:after {
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: var(--ico-arrow-down);
            font-family: 'msp';
            font-size: 1em;
            pointer-events: none;
        }
    }


    select{
        position: relative;
        padding: 4px;
        height: 100%;
        background: white;
        margin-bottom: 0.1em;
    }
}
</style>
