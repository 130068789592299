const rpm = [
    {da: 45, a:55},
    {da: 50, a:60},
    {da: 55, a:65},
    {da: 60, a:70},
    {da: 65, a:75},
    {da: 70, a:80},
    {da: 75, a:85},
    {da: 80, a:90},
    {da: 85, a:95},
    {da: 90, a:100},
    {da: 100, a:110},
    {da: 110, a:120},
];
export default rpm;

