<template>
    <div class="msp-andature-dettaglio" :class="classComponent">
        <span v-if="+dettaglio.ripetute > 1" class="ripetuta-dettaglio ripetuta">{{dettaglio.ripetute}}&nbsp;x&nbsp;</span>
        <div class="serie-container" >
            <div class="serie" v-for="(serie, i_serie) in dettaglio.serie" :key="serie.id">
                <span v-if="+serie.ripetute > 1" class="ripetuta-serie ripetuta">{{serie.ripetute}}&nbsp;x&nbsp;</span>
                <span v-else class="ripetuta-serie ripetuta">1&nbsp;x&nbsp;</span>
                <span class="andature-container">
                    <span class="prefix" v-if="+serie.ripetute > 1">(</span>
                    <span class="andatura" :class="{'last': i_andatura+1 === serie.andature.length}" v-for="(andatura,i_andatura) in serie.andature" :key="andatura.id">
                        <span class="plus" v-if="i_andatura">+</span>
                        <span class="andatura-value" v-html="formatAndatura(andatura)"></span>
                    </span>
                    <span class="suffix" v-if="+serie.ripetute > 1">)</span>
                </span>
                <span class="divider" v-if="i_serie < dettaglio.serie.length - 1"></span>
            </div>
        </div>
    </div>
</template>

<script>
import ums from "@cached/um.json";
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import {methods as methodsAndaturaZonaRange} from '@src/js/vue/mixins/andaturaZonaRange.js'
import _funzioni from '../../../js/msp/funzioni';
import {formatText, fieldIsEmpty, getFieldValue, sportConfig, descrizioneTastoLap, config as configAndatura} from "@src/js/lib/configAndatura.js";
import _sport from "@src/cached/json/sport.json";
const component = {
    props: [
    'um-zone',
    'dettaglio',
    'zone-attive',
    'sport_id',
    'tests',
    ],
  mixins: [
    allenamentoCalcoli,
  ],
  computed: {
        filtered_tests: function () {
            return _funzioni.filterTestsBySportId(this.tests, +this.sport_id);
        },

      classComponent: function () {
          if (+this.dettaglio.ripetute > 1) {
              return "has-ripetute";
          }
      },


  },
  methods: {
      formatText, 
      fieldIsEmpty, 
      getFieldValue, 
    ...methodsAndaturaZonaRange,
        filteredConfigAndatura: function () {
            if (!this.sport_id) return [];

            const sport_codice = _sport.find(el=>el.id===this.sport_id).codice;
            const keys = sportConfig[sport_codice];
            if (!(keys && keys.length)) return [];

            return configAndatura.filter(el=>keys.indexOf(el.id) !== -1);
        },
        filteredConfigAndaturaNotEmpty: function (andatura) {
            return this.filteredConfigAndatura().filter(el=>{
                return !this.fieldIsEmpty(el, andatura);
            });
        },

      descrizioneZona: function (andatura, sep = " ", isNumeric = true) {
          if (andatura.des_zona==="REC") return "";
          let zona_id = andatura.andatura_zona_id;
          const defaultDescrizione = "";
          if (!isNumeric || !(this.filtered_tests && this.filtered_tests.length)) {
              let descrizione = this.zonaRangeDescrizione(andatura, " ");
              return descrizione;
          }
          let test;
          if (this.umZone) {
              test = this.filtered_tests.find(el=>el.um_tipo_codice === this.umZone) 
          } else {
              test = this.filtered_tests[0]; // prende il primo test come default
          }
          if (!test) {
              return defaultDescrizione;
          }
          let zona = this.makeZonaForRange(test,zona_id);
          if (!zona) {
              return defaultDescrizione;
          }
          let result = this.formatDescrizioneZona(zona, andatura);
          let descrizione;
          if (!result) {
              descrizione = defaultDescrizione;
          } else {
              descrizione =  sep + result;
          }


          return descrizione;
      },

      formatAndatura: function (andatura) {
          let result = "";
          const um = ums.find(el=>+el.id===+andatura.um_id);
          if (!um) return result;

          if (andatura.val_andatura) {
              result += " " + this.MSP().formatRisultatoByUmId(andatura.val_andatura,andatura.um_id, um.descrizione,(um.descrizione.indexOf("mm:ss")===-1));

          }
          if (andatura.des_zona) {
              const zona = (andatura.des_zona==="REC") ? "<span class='recupero'> rec. fermo</span>" : ` in  <strong class='zona'>${andatura.des_zona}</strong>`;
              result += zona;
          }
          result += `<span class='range'>${this.descrizioneZona(andatura)}</span>`;
          result += this.renderOptions(andatura);
          result += this.renderTastoLap(andatura);
          return result;
      },

      renderTastoLap: function (andatura) {
          const text = descrizioneTastoLap(null, andatura);
          if (!text) {
              return "";
          }
          return `<em class="lap">${text}</em>`;
      },
      renderOptions: function (andatura) {
          let config = this.filteredConfigAndaturaNotEmpty(andatura);
          const options = config.map((field)=>{
              const text = formatText(field, andatura);
              return `<span class="option option--${field.params.keyAndatura}">${text}</span>`;
          });
          console.log(options);

          if (!(options && options.length)) {

              return "";
          }


          const text = options.join("");
          return `<span class='options'>${text}</span>`;

      }

  }
}
export default component;
</script>

<style lang="scss">
.msp-andature-dettaglio {
    padding: 5px 0;
    text-align: left;
    flex-wrap: nowrap;
    .options {
        background: var(--col-grigio-chiarissimo);
        font-size: 0.8em;
        white-space: wrap;
        line-height: 1.1;
        padding: 5px 3px;
        border-radius: 4px;
    }
    .lap {
        width: 100%;
    }
    .option {
        margin: 0 2px;
        padding: 0 2px;
        display: inline-block;
        white-space: nowrap;
        
    }
    .nome-percorso {
        display: inline-block;
        white-space: nowrap;
        padding: 0px 5px;
        align-items: center;
        background: var(--col-grigio-chiarissimo);
        border-radius: 5px;
    }

    .icon-percorso {
        &:before {
            content: var(--icon);
        }
    }

    .range {
        font-size: 0.8em;
        line-height: 1.9;
        display: inline;
    }
        .recupero {
            font-size: 0.8em;
        }

    .dettaglio-fase {
        width: 100%;
        font-weight: bold;
    }
    .serie-container {
        margin-bottom: 10px;
    }
    .serie {
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 5px;

    }
    .ripetuta {
        font-weight: bold;
    }

    
    &.has-ripetute {
        --col-ripetuta: #000;
        --contrast-ripetuta: #fff;
        --space: 5px;
        border-left: 2px solid var(--col-ripetuta);
        padding-left: var(--space);
        padding-top: 0;
    }
    .ripetuta-dettaglio {
        color: var(--contrast-ripetuta);
        background: var(--col-ripetuta);
        margin-top: calc(-1*var(--space)) !important;
        margin-left: calc(-1*var(--space));
        padding-left: var(--space);
        display: inline-block;

    }

    .divider {
        display: none;
    }

    &.mobile {
        .ripetuta-dettaglio {
            flex: 0;
            padding-right: var(--ui-space);

        }
        .serie-container {

        }
        flex-direction: row;
        .serie {
            display: flex;
            gap: var(--ui-space);
        }
        .andatura {
            display: block;
            .andatura-value {
                display: flex;
                gap: 5px;
                align-items: start;
                margin-bottom: 5px;
                flex-wrap: wrap;
            }

        }
        .andature-container {
            display: block;
        }

        .prefix,
        .suffix,
        .plus {
            display: none !important;
        }

        white-space: nowrap;
    }


}

</style>
