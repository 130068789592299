import rpm from "./rpm.js";
import rangeZone from "./rangeZone.js";
import _percorsi  from '@src/js/vue/mixins/percorsi.js'
import garminSteps  from '@src/cached/json/garminSteps.json'

const nuotoStile = garminSteps.andatura_stile;
const nuotoAttrezzatura = garminSteps.andatura_attrezzatura;
const optionsPercorso = _percorsi.percorsi.map((el)=>({
    id: el.codice,
    label: el.nome,
}));


export const settings = {
    classLabel: "option-label"
}

export const sportConfig = {
    "ciclismo": [
        "rpm",
        "zona_range",
        "percorso",
    ],
    "podismo": [
        "zona_range",
    ],
    "sci": [
        "zona_range",
    ],
    "nuoto": [
        "zona_range",
        "stile",
        "attrezzatura",
    ],
}

export const config = [
    {
        "id": "rpm",
        "type": "rpm",
        "className": "",
        "params": {
            "label": "RPM",
            "format": "number",
            "keyAndatura": ["andatura_rpm_da","andatura_rpm_a"],
            "options": rpm,
            "optionKey": "id",
            "optionValue": "id",
            "optionLabel": "label",
        },
        "view": {
            "formatter": "@ {{andatura_rpm_da}}-{{andatura_rpm_a}} <span class='um'>rpm</span>",
            "formatterPlainText": function (field, context) {
                void(field);
                return `@ ${context.andatura_rpm_da}-${context.andatura_rpm_a} rpm`;
            },
            "emptyFunction": function (field, context) {
                return !(+context.andatura_rpm_da + (+context.andatura_rpm_a));
            },
            "prefix": "@ ",
            "suffix": "rpm"
        }
    },
    {
        "id": "zona_range",
        "type": "zona-range",
        "className": "andatura-zona-range",
        "params": {
            "label": "Range",
            "keyAndatura": "andatura_zona_range",
            "options": rangeZone,
            "optionKey": "id",
            "optionValue": "id",
            "optionLabel": "name",
        },
        "view": {
            "hidden": true,
        }
    },
    {
        "id": "percorso",
        "type": "select",
        "className": "andatura-percorso",
        "params": {
            "label": "Percorso",
            "keyAndatura": "andatura_percorso",
            "options": optionsPercorso,
            "optionKey": "id",
            "optionValue": "id",
            "optionLabel": "label",
        },
        "view": {
            "formatter": "{{andatura_percorso}}",
            "type": "select",
            "emptyFunction": function (field, context) {
                const value = getFieldValue(field, context);
                return !(value && value.length) || (value === 'nessuno');
            }
        }
    },
    {
        "id": "stile",
        "type": "select",
        "className": "andatura-stile",
        "params": {
            "label": "Stile",
            "keyAndatura": "andatura_stile",
            "options": nuotoStile,
            "optionKey": "codice",
            "optionValue": "codice",
            "optionLabel": "label",
        },
        "view": {
            "formatter": "{{andatura_stile}}",
            "type": "select",
        }
    },
    {
        "id": "attrezzatura",
        "type": "select",
        "className": "andatura-attrezzatura",
        "params": {
            "label": "Attrezzatura",
            "keyAndatura": "andatura_attrezzatura",
            "options": nuotoAttrezzatura,
            "optionKey": "codice",
            "optionValue": "codice",
            "optionLabel": "label",
        },
        "view": {
            "formatter": "con {{andatura_attrezzatura}}",
            "type": "select",
        },
            
    },
];

const findSelectedOption = function (key, field, context) {
    const value = context[key];
    const optionValue = field.params.optionValue || "id";
    const optionLabel = field.params.optionLabel || "label";

    const item = field.params.options.find((el)=>{
        if (el.is_hidden) return false;

        return el[optionValue]===value;
    });

    if (item) {
        if (!item.is_hidden) {
            return item[optionLabel];
        } else {
            return "";
        }
    }
}

export const fieldIsEmpty = function (field, context) {
    if (!field.view) return true;
    if (field.view.hidden) return true;
    if (typeof field.view.emptyFunction === 'function') {
        
        return field.view.emptyFunction(field,context);
    }

    if (field.view.type === 'select') {
        const key = field.params.keyAndatura;
        const selectedOption = findSelectedOption(key, field, context);
        if (!selectedOption) {

            return true;
        } else {

            return false;
        }
    }

    const value = getFieldValue(field, context);
    if (!(value && value.length)) return true;
    
    return false;
}

export const formatText = function (field, context, isPlainText) {
    if (fieldIsEmpty(field, context)) {

        return "";
    }

    const formatter = (isPlainText && field.view.formatterPlainText) ? "formatterPlainText" : "formatter";
    if(field.view[formatter]) {
        if (typeof field.view[formatter] === 'function') {
            return field.view[formatter](field, context);  // Chiamata alla funzione
        }

        let hasMissingValue = false; // Flag per controllare se ci sono valori mancanti
        const formattedText = field.view[formatter].replace(/\{\{(.*?)\}\}/g, (match, key) => {
            key = key.trim();

            if (context && context[key] !== undefined) {

                if (field.view.type === 'select') {
                    const selectedOption = findSelectedOption(key, field, context);
                    if (selectedOption) {

                        return selectedOption

                    } else {
                        hasMissingValue = true;

                        return "";
                    }

                }

                return context[key];
            }

            hasMissingValue = true;

            return "";

        });
        return hasMissingValue ? "" : formattedText;
    } else {
        return getFieldValue(field, context);
    }

}
export const descrizioneTastoLap = function (field, context) {
    void(field);

    return (context.lap) ? ' Premi tasto LAP' : null;
}

// Restituisci il valore del campo, nel caso in cui non esista un formatter
export const getFieldValue = function (field, context) {
    if (field.params.keyAndatura && Array.isArray(field.params.keyAndatura)) {
        return field.params.keyAndatura.map(key => context[key]).join('-');
    }
    return context[field.params.keyAndatura] || '';
}

export default config;
