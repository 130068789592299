<template>
<div class="msp-attivita-dettaglio">
    

        <div class="boxes">
            
            <div class="msp-card">
                <div class="msp-card__title">
                Distanza
                </div>
                <div class="msp-card__content">
                    {{attivita.riepilogo.distanza_totale}}
                </div>
            </div>
			<div class="msp-card">
                <div class="msp-card__title">
               Tempo 
                </div>
                <div class="msp-card__content">
                    {{attivita.riepilogo.tempo_effettivo}}
                </div>
            </div>
            <div class="msp-card">
                <div class="msp-card__title">
                Trimp
                </div>
                <div class="msp-card__content">
                    {{attivita.riepilogo.trimp}}
                </div>
            </div>
        </div>



        


        <table class="dati">
        <thead>
        <tr>
       <th></th> 
       <th>Media</th> 
       <th>Max</th> 
        </tr>
        </thead>
        <tbody>
        <tr>
            <td class="label">Velocità</td> 
            <td>{{attivita.riepilogo.velocita_avg}}</td> 
            <td>{{attivita.riepilogo.velocita_max}}</td> 
        </tr>
        <tr>
            <td class="label">Cardio</td> 
            <td>{{attivita.riepilogo.cardio_avg}}</td> 
            <td>{{attivita.riepilogo.cardio_max}}</td> 
        </tr><tr>
            <td class="label">Potenza</td> 
            <td>{{attivita.riepilogo.potenza_avg}}</td> 
            <td>{{attivita.riepilogo.potenza_max}}</td> 
        </tr><tr>
            <td class="label">Cadenza</td> 
            <td>{{attivita.riepilogo.cadenza_avg}}</td> 
            <td>{{attivita.riepilogo.cadenza_max}}</td> 
        </tr><tr>
            <td class="label">Calorie</td> 
            <td colspan=2>{{attivita.riepilogo.calorie}}</td> 
        </tr><tr>
            <td class="label">Energia</td> 
            <td colspan=2>{{attivita.riepilogo.energia}}</td> 
        </tr>
        </tbody>
        </table>
<v-card>
<v-card-title>
Dettaglio dei lap
</v-card-title>
<v-card-text>
<msp-grafico-lap
:attivita="attivita"
>
</msp-grafico-lap>
<template>

  <v-simple-table
  dense
  fixed-header
  class="tabella-lap" 
  >

    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" v-for="(label, index) in labels" :key="index" >
            <span v-html="label"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in laps"
          :key="item.count"
        >
          <td v-for="(value, key) in item" :key="key" v-html="value"></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

</v-card-text>

</v-card>

<v-card class="mb-5" v-if="hasZone">
<v-card-title>
Zone attive
</v-card-title>
<v-card-text>
    <msp-zone-attive
    :attivita="attivita"
    >
    </msp-zone-attive>
</v-card-text>

</v-card>
        


<msp-attivita-riepilogo-cp
:token="token"
:attivita_id="attivita.id">
</msp-attivita-riepilogo-cp>							


      <!-- attività -->
    
          

</div></template> 


<script>
// vedi funzioni php
import mspZoneAttive from "./MspZoneAttive.vue"
import mspGraficoLap from "./MspGraficoLap.vue"
import mspAttivitaRiepilogoCp from "@src/js/vue/include/MspAttivitaRiepilogoCp.vue"
import _attivita from "@mixins/attivita.js"
import _storage from "@mixins/storage.js"

const component = {
  components: {
      mspZoneAttive,
      mspGraficoLap,
      mspAttivitaRiepilogoCp,
  },

  mixins: [
  ],
  name: 'attivitaSci',
  props: [
    'attivita',
  ],
  watch: {
        attivita: function (no) {
            if (!(no)) {
                this.dismissModal();
            }
        },
  },
    computed: {
        hasZone: _attivita.hasZone,
        labels: _attivita.labels,
        laps: _attivita.laps,
    },
  data() {
    return {
        token: _storage.getAuthToken(),
    }
  },
  mounted() {
  },

  methods: {


  }
}
export default component;
</script>
<style lang="scss">

</style>
