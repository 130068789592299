<template>
    <div class="edit-zona">
        <div v-if="!edit" v-html='formatRisultatoByUmId(filteredValore, um_id, um_descrizione, false)' />
        <span class="edit" v-else>
            <input-select v-if="isTime(um_id)" :format="'ms'" :value="hmsValue" @input="updateHms" :placeholder="formatRisultatoByUmId(filteredValore, um_id, um_descrizione, false)"/>
            <input v-else size=3 :value="filteredValore" @change="updateValue" type="text" :placeholder="formatRisultatoByUmId(filteredValore, um_id, um_descrizione, false)"/>
        </span>
    </div>
</template>

<script>

import _funzioni from '@src/js/msp/funzioni.js';
import _constants from '@src/js/msp/constants.js';
import InputSelect from "@src/js/vue/include/InputSelect.vue";
const component = {
    components: {
        InputSelect,
    },
    props: ['zona', 'mode', 'key_valore', 'um_id', 'um_descrizione' ],
    data: function () {
        const data = {
            value: 0,

        };
        return data;
    },
    created: function () {


    },
    computed: {
        
        hmsValue: function () {
            // il risultato è salvato come min/km, ma a me servono i sec/mt
            const converted = this.um2mt(this.filteredValore);
            return _funzioni.sec2hms(converted);
        },

        edit: function () {
            if (!this.filteredValore) return false;
            return this.mode === 'edit';
        },

        filteredValore: function () {

            let fv;
            if (!this.zona) {
                return 0;
            }
            if (this.key_valore!="media") {
                fv = this.zona[this.key_valore];
            } else {
                let media = (this.zona.da + this.zona.a)/2;
                fv = media;
            }
            return fv;
        },
    },

    mounted: function () {
    },

    watch: {
    },
    methods: {
        um2um: _funzioni.um2um,
        isTime: _funzioni.isTime,

        um2mt: function (value) {
            return this.um2um(value, this.um_id, _constants.UMS.mt);
        },

        mt2um: function (value) {
            return this.um2um(value, _constants.UMS.mt, this.um_id);
        },

        updateValue: function (ev) {
            if (!ev.target.value) return;

            this.value = Number(ev.target.value);
            this.update();
        },

        updateHms: function (hms) {
            this.value = this.mt2um(_funzioni.hms2sec(hms));
            this.update();
        },

        update: function () {
            if (!this.value) return;

            let payload = {
                zona: this.zona,
                value: this.value,
                key_valore: this.key_valore,
            };
            this.$emit('update', payload);
        },
        formatRisultatoByUmId(val, um_id, um_descrizione, hasUm = true) {
            return _funzioni.formatRisultatoByUmId(val, um_id, um_descrizione, hasUm);
        },


    }
}
    export default component;
    </script>

<style lang="scss">
.buttons {
    display: flex;
}
</style>
