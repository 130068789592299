import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import test from "@mixins/test.js";
import api from "@mixins/api.js";
import env from "@mixins/environment.js"

export default {
  props: [
    'fixedData',
  ],
  mixins: [
    allenamentoCalcoli,
    test,
	env,

  ],
  data: function () {
    return {
      um: '',
      calendario_test_id: 0,
      data: '',
      risultato: 0,
        canSubmit: true,
    }

  },

  computed: {


  },
  mounted: function ()
  {
    this.loadNomeZone();
  },
  methods: {
    clear: function ()
    {
      this.passo = new this.MSP().Passo();
      this.soglia = new this.MSP().Passo();
      this.risultato = null;
    },


    setRisultato: function (value) {
      this.risultato = value;
    },

    setResultsCalcolo: function (values) {
      this.setZone(values);
      this.setRisultato(values.risultato);
    },

    setZone: function (values) {
      this.id_tipo_um = values.id_tipo_um
      this.passo.value = values.passo;
      this.soglia.value = values.soglia;
      this.soglia.label = values.des_soglia;
      this.zone = values.zone;
    },

    resetZone: function () {
      this.id_tipo_um = 0
      this.passo.value = 0;
      this.soglia.value = 0;
      this.soglia.label = "";
      this.zone = [];
    },

    setDataForSaving: function () {
      if (!this.test) {
        return false;
      }
        var values = {
            'risultato': this.risultato,
            'test_id': this.test.id,
            'utente_id': this.atleta && this.atleta.id, //V2
            'data': this.test.inputs.data, //V2: TODO usare data da calendario
            'passo': this.passo.value,
            'fc_max': this.test.inputs.fc_max,
            'fc_media': this.test.inputs.fc_media,
            'note': this.test.inputs.note,
        }
      return values;
    },

    save: function () {
      return new Promise((resolve, reject)=>{
      this.saveDB(this.setDataForSaving())
      .then(
        (result) => {
          if (result.success) {
            this.$emit('save', this.calendario_test_id, result.data);
          }
          resolve(result);
        },
        (error) => {
        reject(error);
        }
      );
      });
    },

        resetCanSubmit: function () {
            this.canSubmit = true;
        },
      saveActive: function () {
          if (!this.canSubmit) return;

          this.canSubmit = false;
          return new Promise((resolve, reject)=>{
              this.saveDB(this.setDataForSaving())
                  .then((result) => {
                      if (result.success) {
                          this.$emit('save', this.calendario_test_id, result.data);

                          let url = this.api_base_url+"/api/v2/risultato.php?attiva=1&id="+result.data.evento_id;
                          api(url, "put", null)
                              .then(
                                  (result) => {
                                      if (result.success) {
                                          this.$emit('activate', result.data);
                                          resolve(result);
                                      }
                                  },
                                  (error) => {
                                      console.log('error', error);
                                      reject(error);
                                  }
                              );
                      } else {
                          this.resetCanSubmit();
                      }
                  })
                  .catch((error) => {
                      console.log('error', error);
                      reject(error);
                  }
                  );
          });
      },

      saveDB: function (data) {
          const url = this.api_base_url+'/api/v2/risultato.php';

          return new Promise((resolve, reject)=>{
              api(url,'post',data)
                  .then(
                      (result)=>{
                          if (result.status === 201) {
                              resolve(result);
                          } else {
                              this.error = "Login fallito."
                              resolve({success:false});
                          }
                      }
                  )
                  .catch(
                      (error)=>{
                          reject(error);
                      }
                  )
          });
      },

  onCalcola: function () {
  },

  changeTest: function (id)
  {
    // this.clear();
    this.loadTest(id)
    .then(
      (result) => {
        if (result.success) {
          this.test = result.data;
        }
      },
      (error) => {
        console.log('error', error);
      }
    );

  },


  changeSport: function (sport_id)
  {
    this.loadEmptyZonesPromise()
    .then(
      (result) => {
        if (result.success) {
          this.zone = result.data;
        }
      },
      (error) => {
        console.log('error', error);
      }
    );
    this.loadTests(sport_id, false)
    .then(
      (result) => {
        if (result.success) {
          this.tests = result.data;
          if (this.tests.length === 1) {
            this.test = this.tests[0];
          }
        }
      },
      (error) => {
        console.log('error', error);
      }
    );

  },
  clearTest: function ()
  {
    //this.test = null;
  },



}
};
